<template>
  <sidenav :orientation="orientation" :class="curClasses">
    <!-- Inner -->
    <div class="sidenav-inner" :class="{ 'py-1': orientation !== 'horizontal' }">
      <sidenav-router-link icon="ion ion-md-speedometer" to="/" :exact="true"
      >Mainpage</sidenav-router-link>
      <!-- v-if="gosterilecekMi(Yetki.ADMIN)"-->
      <sidenav-router-link icon="ion ion-ios-globe" to="/dil" :exact="true"
      >Language
      </sidenav-router-link>
      <sidenav-router-link icon="ion ion-ios-book" to="/eser" :exact="true"
      >Book
      </sidenav-router-link>
      <sidenav-router-link icon="ion ion-ios-bookmark" to="/fihrist" :exact="true"
      >Title
      </sidenav-router-link>
      <sidenav-router-link icon="ion ion-ios-document" to="/sayfa" :exact="true"
      >Page
      </sidenav-router-link>
    </div>
  </sidenav>
</template>

<script>
import { Sidenav, SidenavLink, SidenavRouterLink, SidenavMenu, SidenavHeader, SidenavBlock, SidenavDivider } from "@/vendor/libs/sidenav";

export default {
  name: "app-layout-sidenav",
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider,
    /* eslint-enable vue/no-unused-components */
  },
  data() {
    return {
      developmentMi: process.env.NODE_ENV === "development",
    };
  },

  props: {
    orientation: {
      type: String,
      default: "vertical",
    },
  },

  computed: {
    curClasses() {
      let bg = this.layoutSidenavBg;

      if (this.orientation === "horizontal" && (bg.indexOf(" sidenav-dark") !== -1 || bg.indexOf(" sidenav-light") !== -1)) {
        bg = bg.replace(" sidenav-dark", "").replace(" sidenav-light", "").replace("-darker", "").replace("-dark", "");
      }

      return (`bg-${bg} ` + (this.orientation !== "horizontal" ? "layout-sidenav" : "layout-sidenav-horizontal container-p-x flex-grow-0"));
    },
  },

  methods: {
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0;
    },

    isMenuOpen(url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== "horizontal";
    },

    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed();
    },
    gosterilecekMi() {
      for (let i = 0; i < arguments.length; i++) {
        if (arguments[i] === this.kullaniciYetki) return true;
      }
      return false;
    },
  },
};
</script>
