import layoutHelpers from "@/layout/helpers.js";
import { Turkish } from "flatpickr/dist/l10n/tr.js";

export default function () {
  return {
    // Public url
    publicUrl: process.env.BASE_URL,

    // Layout helpers
    layoutHelpers,

    // Check for RTL layout
    get isRtlMode() {
      return document.documentElement.getAttribute("dir") === "rtl" || document.body.getAttribute("dir") === "rtl";
    },

    // Check if IE
    get isIEMode() {
      return typeof document.documentMode === "number";
    },

    // Check if IE10
    get isIE10Mode() {
      return this.isIEMode && document.documentMode === 10;
    },

    // Layout navbar color
    get layoutNavbarBg() {
      return "navbar-theme";
    },

    // Layout sidenav color
    get layoutSidenavBg() {
      return "sidenav-theme";
    },

    // Layout footer color
    get layoutFooterBg() {
      return "footer-theme";
    },

    // Animate scrollTop
    scrollTop(to, duration, element = document.scrollingElement || document.documentElement) {
      if (element.scrollTop === to) return;
      const start = element.scrollTop;
      const change = to - start;
      const startDate = +new Date();

      // t = current time; b = start value; c = change in value; d = duration
      const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      const animateScroll = () => {
        const currentDate = +new Date();
        const currentTime = currentDate - startDate;
        element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          element.scrollTop = to;
        }
      };

      animateScroll();
    },

    // *************************************
    yuklendi: false,
    isBusy: false,
    get idParam() {
      return this.getIntParam("id");
    },
    get apipath() {
      return "/api" + document.location.pathname + document.location.search;
    },
    stringToSlug(str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      const from = "àáãäâèéëêıìíïîòóöôùúüûñçğ·/_,:;";
      const to = "aaaaaeeeeiiiiioooouuuuncg------";

      for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return str;
    },
    getIntParam(param, varsayilan) {
      const urlParams = new URLSearchParams(window.location.search);
      return parseInt(urlParams.get(param)) || varsayilan || 0;
    },

    getStrParam(param, varsayilan) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param) || varsayilan || "";
    },
    get path() {
      return document.location.pathname + document.location.search;
    },
    get datetimeConfig() {
      return {
        locale: Turkish,
        enableTime: true,
        altInput: true,
        allowInput: true,
        minuteIncrement: 15,
        time_24hr: true,
        dateFormat: "Z",
        altFormat: "d-m-Y H:i",
      };
    },
  };
}
