import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import NotFound from "@/components/NotFound";

// Layouts
import Layout1 from "@/layout/Layout1";
//import { Yetki } from "@/enums";

Vue.use(Router);
Vue.use(Meta);

const router = new Router({
  base: "",
  mode: "history",
  routes: [
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login"),
    },
    {
      path: "/",
      redirect: "",
      component: Layout1,
      children: [
        {
          path: "",
          component: () => import("@/components/Home"),
        },
        {
          path: "kullanici",
          component: () => import("@/views/kullanici/Kullanici"),
          /*
          meta: [
            {
              Roles: [Yetki.ADMIN, Yetki.MODERATOR],
            },
          ],
          */
        },
        {
          path: "dil",
          component: () => import("@/views/dil/Dil"),
        },
        {
          path: "eser",
          component: () => import("@/views/eser/Eser"),
        },
        {
          path: "fihrist",
          component: () => import("@/views/fihrist/Fihrist"),
        },
        {
          path: "sayfa",
          component: () => import("@/views/sayfa/Sayfa"),
        },
      ],
    },
    {
      // 404 Not Found page
      path: "*",
      component: NotFound,
    },
  ],
});

export default router;
